import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Country } from 'src/app/model/country';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CountriesService } from 'src/app/services/countries.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { TitleService } from 'src/app/services/title.service';
import { WikipediaScannerService } from 'src/app/services/wikipedia.service';
import { WikiDataScannerService } from 'src/app/services/wikidata.service';

@Component({
  selector: 'app-list-countries',
  templateUrl: './list-countries.component.html',
  styleUrls: ['./list-countries.component.css']
})
export class ListCountriesComponent implements OnInit {

  showLoader = true;

  countryCode: string;
  name: string;

  // scannerStatus: boolean;
  // scannerStatusLoader: boolean;
  // parserStatus: boolean;
  // parserStatusLoader: boolean;
  // parserConcurrency: number;
  // parserQueueDepth: number;

  wdscannerStatus: boolean;
  wdscannerStatusLoader: boolean;
  wdparserStatus: boolean;
  wdparserStatusLoader: boolean;
  wdparserConcurrency: number;
  wdScannerOffset: number;
  wdparserQueueDepth: number;

  countries: Country[];

  constructor(
    private titleService: TitleService,
    private countryService: CountriesService,
    // private wikiScannerService: WikipediaScannerService,
    private wikiDataService: WikiDataScannerService,
    public authService: AuthenticationService,
    private notificationService: NotificationsService,
    private router: Router
  ) { }

  ngOnInit() {
    this.refreshCountries();
    this.titleService.setTitle('Countries');

    this.refreshWikiDataSettings();
  }

  addCountry() {
    if (this.countryCode && this.name) {
      this.showLoader = true;
      const meta = {};
      this.countryService.createCountry(this.countryCode, this.name, meta).subscribe(() => this.refreshCountries());
    } else {
      this.notificationService.showInfo('Missing details', 'Please fill out ISO country code and name first', 'warning', 'Close', false);
    }
  }

  refreshWikiDataSettings() {
    this.wdparserStatusLoader = true;
    this.wikiDataService.getParserStatus().subscribe((s) => {
      this.wdparserConcurrency = s.concurrent;
      this.wdparserStatus = s.status.startsWith('Enabl');
      this.wdparserQueueDepth = s.parserQueueDepth;

      if (!this.wdparserQueueDepth && this.wdparserQueueDepth != 0) {
        this.wdparserQueueDepth = '?' as any;
      }

      this.wdparserStatusLoader = false;
    });
    this.wdscannerStatusLoader = true;
    this.wikiDataService.getScannerStatus().subscribe((s) => {
      this.wdscannerStatus = s.status;
      this.wdScannerOffset = s.offset;
      this.wdscannerStatusLoader = false;
    });
  }

  refreshCountries() {
    this.showLoader = true;
    this.countryService.listCountries().subscribe((c) => {
      this.countries = c;
      this.showLoader = false;
    });
  }


  // scanCountry(isocode: string) {
  //   this.showLoader = true;
  //   this.countryService.rescanCountry(isocode).subscribe((c) => {
  //     this.refreshCountries();
  //     this.notificationService.showInfo('Scan of external sources requested', c, 'info', 'Close', false);
  //   });

  // }

  async deleteCountry(isocode: string) {
    const resp = await this.notificationService.showQuestion('Delete country?',
                      'Country with id ' + isocode + ' will be deleted. Any external persons found from scanning will remain.',
                      'warning', 'Delete', 'Cancel');

    if (resp) {
      this.showLoader = true;
      this.countryService.deleteCountry(isocode).subscribe((c) => {
        this.refreshCountries();
      });
    }
  }

  editCountry(isocode: string) {
   this.router.navigate(['/countries/' + isocode])
  }


  toggleWDScannerStatus() {
    this.wdscannerStatusLoader = true;
    this.wikiDataService.updateScannerStatus(this.wdscannerStatus).subscribe(() => {
      this.wikiDataService.getScannerStatus().subscribe((s) => {
        this.wdscannerStatus = s.status;
        this.wdscannerStatusLoader = false;
      });
    });
  }

  toggleWDParserStatus() {
    this.wdparserStatusLoader = true;
    this.wikiDataService.updateParserStatus(this.wdparserStatus).subscribe(() => {
      this.wikiDataService.getParserStatus().subscribe((s) => {
        this.wdparserStatus = s.status.startsWith('Enabl');
        this.wdparserStatusLoader = false;
      });
    });
  }

  updateWikiDataConcurrency() {
    this.wdparserStatusLoader = true;
    this.wikiDataService.updateParserConcurrency(this.wdparserConcurrency).subscribe((s) => {
      this.notificationService.showInfo('Concurrency updated', s, 'info', 'Close', false);

      this.wdparserStatusLoader = false;
    });
    this.wikiDataService.updateScannerOffset(this.wdScannerOffset).subscribe((s) => {
      this.notificationService.showInfo('Offset changed', s, 'info', 'Close', false);

      this.wdparserStatusLoader = false;
    });

  }




}
