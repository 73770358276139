import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MetataggerCustomer } from '../model/metatagger-customer';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class CustomersService {

  public url = 'v1';

  constructor(public http: HttpClient,
              private authenticationService: AuthenticationService) { }

  listCustomers(): Observable<MetataggerCustomer[]> {
      return this.http.get<MetataggerCustomer[]>(this.url + '/customers?v=' + new Date().getTime());
  }

  createCustomer(id: string, name: string): Observable<string> {
      return this.http.put<string>(this.url + '/customers?v=' + new Date().getTime(), {
        id, name
      });
  }

  redeployCustomer(id: string): Observable<string> {
      return this.http.post<string>(this.url + '/customers/' + id + '/redeploy', {});
  }

  deleteCustomer(id: string): Observable<string> {
      return this.http.delete<string>(this.url + '/customers/' + id, {});
  }

  fetchCustomer(id: string): Observable<MetataggerCustomer> {
      return this.http.get<MetataggerCustomer>(this.url + '/customers/' + id + '?v=' + new Date().getTime());
  }

  saveCustomer(cust: MetataggerCustomer): Observable<string> {
      return this.http.post<string>(this.url + '/customers/' + cust.id, {
        active: cust.active,
        name: cust.name,
        level: cust.subscriptionLevel
      });
  }
}
