import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Country } from '../model/country';
import { MetataggerCustomer } from '../model/metatagger-customer';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class CountriesService {

  public url = 'v1';

  constructor(public http: HttpClient,
              private authenticationService: AuthenticationService) { }

  listCountries(): Observable<Country[]> {
      return this.http.get<Country[]>(this.url + '/countries?v=' + new Date().getTime());
  }

  createCountry(id: string, name: string, meta: any): Observable<string> {
      return this.http.put<string>(this.url + '/countries?v=' + new Date().getTime(), {
        id, name, metadata: meta
      });
  }

  rescanCountry(id: string, maxResults?: number): Observable<string> {
      return this.http.post<string>(this.url + '/countries/' + id + '/rescan?maxResults=' + maxResults, {});
  }

  deleteCountry(id: string): Observable<string> {
      return this.http.delete<string>(this.url + '/countries/' + id, {});
  }

  getCountry(id: string): Observable<Country> {
      return this.http.get<Country>(this.url + '/countries/' + id, {});
  }

  saveCountry(country: Country): Observable<string> {
      return this.http.post<string>(this.url + '/countries/' + country.id, country);
  }

  clearExternalPersons(): Observable<string[]> {
    return this.http.delete<string[]>(this.url + '/settings/external-persons');
  }
}
