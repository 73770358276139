<div class="container-fluid page-body-wrapper">
    <div class="main-panel">
      <div class="content-wrapper">
        <div class="row">
          <div class="col-6 grid-margin stretch-card" *ngIf="authService.hasAdminAccess()">
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">Edit Country</h4>
                <p class="card-description">Add new country to scan for external persons</p>
                <form class="form-sample">
                  <div class="col-8">
                    <div class="form-group row">
                      <label class="col-6 col-form-label" for="countryCode">ISO Country Code (two-letter)</label>
                      <div class="col-3">
                        <input name="countryCode" class="form-control" id="countryCode" type="text" [(ngModel)]="country.id"
                          [readOnly]="true" placeholder="" />
                      </div>
                    </div>
                  </div>
                  <div class="col-8">
                    <div class="form-group row">
                      <label class="col-6 col-form-label" for="name">Name</label>
                      <div class="col-6">
                        <input name="name" class="form-control" id="name" type="text" [(ngModel)]="country.name"
                          [readOnly]="!authService.hasAdminAccess()" placeholder="" />
                      </div>
                    </div>
                  </div>
                </form>
                <button class="btn btn-outline-primary mb-2" (click)="saveCountry()" type="submit"
                  [disabled]="!authService.hasAdminAccess()" style="margin: 0px;">Save changes</button>
                <div class="form-group">
                  <app-loader *ngIf="showLoader"></app-loader>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>