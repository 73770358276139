import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CountriesService } from 'src/app/services/countries.service';
import { EnvironmentsService } from 'src/app/services/environments.service';
import { TitleService } from 'src/app/services/title.service';

@Component({
  selector: 'app-clear-external-persons',
  templateUrl: './clear-external-persons.component.html',
  styleUrls: ['./clear-external-persons.component.css']
})
export class ClearExternalPersonsComponent implements OnInit {


  public result: string;
  public results: string[];
  showDeleteLoader = false;

  constructor(
    public authService: AuthenticationService,
    private titleService: TitleService,
    private countryService: CountriesService
    ) { }

  ngOnInit() {
    this.titleService.setTitle('!!! System RESET !!!');

  }

public resetAll() {
    this.showDeleteLoader = true;
    this.countryService.clearExternalPersons().subscribe((res) => {
      this.results = res;
      this.showDeleteLoader = false;
    }, (err) => {
      this.showDeleteLoader = false;
      this.result = 'Deleting all data failed';
      if (err.error instanceof Error) {
        // A client-side or network error occurred. Handle it accordingly.
        this.result += ' : Client-side error: ' + err.error.message;
        console.error('A Client-side network error occurred:', err.error.message);
      } else {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong,
        // tslint:disable-next-line:triple-equals
        if (err.status != '0') {
          this.result += ' : Server said: [' + err.status + '] ' + err.status;
        } else {
          this.result += ' Server did not respond in time - Please try again.';
        }
        console.error(`Backend returned code ${err.status}, body was: ${err.error}`);
      }
    }
    );
  }


}
