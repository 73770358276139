<nav class="navbar horizontal-layout col-lg-12 col-12 p-0" *ngIf="user && user.email">
  <div class="nav-top flex-grow-1">
    <div class="container d-flex flex-row h-100">
      <div class="text-center navbar-brand-wrapper d-flex align-items-center">
        <a class="navbar-brand brand-logo" href=""><img src="assets/images/metatagger-logo-01.png" alt="logo" /></a>
      </div>
      <div class="navbar-menu-wrapper d-flex align-items-stretch justify-content-between flex-grow-1">
        <form class="search-field d-none d-md-flex" action="#">
          <div class="form-group mb-0">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text"><i class="icon-magnifier"></i></span>
              </div>
              <input type="text" name="elvisId_id" class="form-control" style="width: 400px"
                placeholder="Search for stuff here..." [(ngModel)]="elvisId" (keydown.enter)="searchElvisId()" />
            </div>
          </div>
        </form>
        <ul class="navbar-nav navbar-nav-right mr-0 ml-auto">
          <li class="nav-item dropdown">
            <a class="nav-link count-indicator dropdown-toggle" id="notificationDropdown" href="#"
              data-toggle="dropdown">
              <i class="icon-bell"></i>
              <span *ngIf="errorsShown.length > 0" class="count">{{ lastCount }}</span>
            </a>
            <div class="dropdown-menu dropdown-menu-right navbar-dropdown preview-list"
              aria-labelledby="notificationDropdown">
              <a class="dropdown-item py-3">
                <p class="mb-0 font-weight-medium float-left">You have {{ lastCount }} new notifications
                </p>
                <span class="badge badge-pill badge-danger float-right">View all</span>
              </a>
              <div *ngFor="let e of errorsShown">
                <div class="dropdown-divider"></div>
                <a class="dropdown-item preview-item">
                  <div class="preview-thumbnail">
                    <div class="preview-icon bg-danger">
                      <i class="icon-exclamation mx-0"></i>
                    </div>
                  </div>
                  <div class="preview-item-content">
                    <h6 class="preview-subject font-weight-normal text-light mb-1">{{ e.name }} {{ e.status }}</h6>
                    <p class="font-weight-light small-text mb-0">
                      {{ e.time | amTimeAgo }}
                    </p>
                  </div>
                </a>
              </div>
            </div>
          </li>
          <li class="nav-item nav-profile">
            <app-gravatar [email]="user.email" (click)="refreshToken()" *ngIf="!this.showTokenLoader"></app-gravatar>
            <app-inline-loader *ngIf="this.showTokenLoader"></app-inline-loader>
          </li>
          <li class="nav-item nav-profile dropdown">
            <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown" id="profileDropdown">
              <span class="nav-profile-name">{{ user.fullname }}</span>
            </a>
            <div class="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="profileDropdown">
              <a class="dropdown-item" routerLink="/profile">
                <i class="icon-settings text-primary mr-2"></i>
                Settings
              </a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" (click)="logout()">
                <i class="icon-logout text-primary mr-2"></i>
                Logout
              </a>
            </div>
          </li>
        </ul>
        <button class="navbar-toggler align-self-center" type="button" data-toggle="minimize">
          <span class="icon-menu text-light"></span>
        </button>
      </div>
    </div>
  </div>
  <div class="nav-bottom">
    <div class="container">
      <ul class="nav page-navigation">
        <li class="nav-item">
          <a routerLink="/dashboard" class="nav-link"><i class="link-icon icon-screen-desktop"></i><span
              class="menu-title">Customers</span></a>
        </li>
        <li class="nav-item">
          <a routerLink="/countries" class="nav-link"><i class="link-icon icon-globe"></i><span
              class="menu-title">Countries</span></a>
        </li>
        <li class="nav-item">
          <a routerLink="/providers" class="nav-link"><i class="link-icon icon-globe"></i><span
              class="menu-title">Providers</span></a>
        </li>
        <li class="nav-item mega-menu" *ngIf="authenticationService.hasAdminAccess()">
          <a class="nav-link"><i class="link-icon icon-docs"></i><span class="menu-title">Other</span><i
              class="menu-arrow"></i></a>
          <div class="submenu">
            <div class="col-group-wrapper row">
              <div class="col-group col-md-3">
                <p class="category-heading">Information</p>
                <ul class="submenu-item">
                  <li class="nav-item"><a class="nav-link" routerLink="/datamodel">DataModel</a></li>
                </ul>
              </div>
              <div class="col-group col-md-3">
                <p class="category-heading">Settings</p>
                <ul class="submenu-item">
                  <li class="nav-item"><a class="nav-link" routerLink="/bugs"
                      *ngIf="authenticationService.hasReportBugAccess()">Bug Reports</a></li>
                  <li class="nav-item"><a class="nav-link" routerLink="/users">List Users</a></li>
                  <li class="nav-item"><a class="nav-link" routerLink="/settings/external-persons/clear">Clear All External Persons</a></li>
                </ul>
              </div>
            </div>
          </div>
        </li>

      </ul>
    </div>
  </div>
</nav>