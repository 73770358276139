<div class="container-fluid page-body-wrapper">
    <div class="main-panel">
      <div class="content-wrapper">
        <div class="row">
          <div class="col-6 grid-margin stretch-card" *ngIf="authService.hasAdminAccess()">
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">Edit Customer</h4>
                <form class="form-sample">
                  <div class="col-8">
                    <div class="form-group row">
                      <label class="col-6 col-form-label" for="countryCode">Id</label>
                      <div class="col-3">
                        <input name="countryCode" class="form-control" id="id" type="text" [(ngModel)]="customer.id"
                          [readOnly]="true" placeholder="" />
                      </div>
                    </div>
                  </div>
                  <div class="col-8">
                    <div class="form-group row">
                      <label class="col-6 col-form-label" for="name">Name</label>
                      <div class="col-6">
                        <input name="name" class="form-control" id="name" type="text" [(ngModel)]="customer.name"
                          [readOnly]="!authService.hasAdminAccess()" placeholder="" />
                      </div>
                    </div>
                  </div>
                  <div class="col-8">
                    <div class="form-group row">
                      <label class="col-6 col-form-label" for="name">Subscription Level</label>
                      <div class="col-6">
                        <select name="type" class="form-control" id="type" type="text" [(ngModel)]="customer.subscriptionLevel">
                            <option value="10">Bronze</option>
                            <option value="20">Silver</option>
                            <option value="30">Gold</option>
                          </select>
                      </div>
                    </div>
                  </div>
                </form>
                <button class="btn btn-outline-primary mb-2" (click)="saveCustomer()" type="submit"
                  [disabled]="!authService.hasAdminAccess()" style="margin: 0px;">Save changes</button>
                <div class="form-group">
                  <app-loader *ngIf="showLoader"></app-loader>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>