import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, LOCALE_ID } from '@angular/core';
import {NgbDatepickerModule} from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoaderComponent } from './widgets/loader/loader.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TopMenuComponent } from './components/top-menu/top-menu.component';
import { GravatarComponent } from './widgets/gravatar/gravatar.component';
import { NgMathPipesModule } from 'angular-pipes';
import { LoginComponent } from './pages/login/login.component';
import { FormsModule } from '@angular/forms';
import { WindowRef } from './helpers/WindowRef';
import { JwtInterceptor, ErrorInterceptor } from './helpers';
import { ProfileSettingsComponent } from './pages/profile-settings/profile-settings.component';
import { InlineLoaderComponent } from './widgets/inline-loader/inline-loader.component';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { ErrorsHandler } from './helpers/ErrorHandler';
import { Ng5SliderModule } from 'ng5-slider';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ListUsersComponent } from './pages/other/list-users/list-users.component';
import { ContextMenuModule } from 'ngx-contextmenu';
import { PairsPipe } from './helpers/PairPipe';
import { DurationPipe } from './helpers/duration-pipe';
import { SearchLogsComponent } from './pages/other/search-logs/search-logs.component';
import { SqlEditorComponent } from './components/sql-editor/sql-editor.component';
import { DelayedResponsesInterceptor } from './helpers/delayedresponses.interceptor';
import { ManageComponent } from './pages/bugreports/manage/manage.component';
import { ListAllComponent } from './pages/bugreports/components/list-all/list-all.component';
import { EditComponent } from './pages/bugreports/components/edit/edit.component';
import { ListBatchJobsComponent } from './pages/other/batch/list-batch-jobs/list-batch-jobs.component';
import { MomentModule } from 'ngx-moment';
import { CompareLogsComponent } from './pages/other/compare-logs/compare-logs.component';
import { NumberSuffixPipe } from './components/number-suffix/number-suffix.pipe';
import { DatamodelComponent } from './pages/other/datamodel/datamodel.component';
import { CreateCustomerComponent } from './pages/customers/create-customer/create-customer.component';
import { ListCountriesComponent } from './pages/countries/list-countries/list-countries.component';
import { ClearExternalPersonsComponent } from './pages/other/settings/clear-external-persons/clear-external-persons.component';
import { EditCountryComponent } from './pages/countries/edit-country/edit-country.component';
import { EditCustomerComponent } from './pages/customers/edit-customer/edit-customer.component';
import { ListProvidersComponent } from './pages/providers/list-providers/list-providers.component';


@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent,
    TopMenuComponent,
    GravatarComponent,
    LoginComponent,
    ProfileSettingsComponent,
    InlineLoaderComponent,
    DashboardComponent,
    DatamodelComponent,
    ListUsersComponent,
    PairsPipe,
    SearchLogsComponent,
    SqlEditorComponent,
    ManageComponent,
    ListAllComponent,
    EditComponent,
    ListBatchJobsComponent,
    DurationPipe,
    CompareLogsComponent,
    NumberSuffixPipe,
    CreateCustomerComponent,
    ListCountriesComponent,
    ClearExternalPersonsComponent,
    EditCountryComponent,
    EditCustomerComponent,
    ListProvidersComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgMathPipesModule,
    NgbDatepickerModule,
    Ng5SliderModule,
    MomentModule,
    FormsModule,
    SweetAlert2Module.forRoot({
      buttonsStyling: false,
      customClass: 'modal-content',
      confirmButtonClass: 'btn btn-outline-success',
      cancelButtonClass: 'btn btn-outline-warning'
  }),
  ContextMenuModule.forRoot()
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: ErrorsHandler
    },
    WindowRef,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: DelayedResponsesInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'en-US' }
],
  entryComponents: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
