<div class="container-fluid page-body-wrapper">
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="row">
        <div class="col-6 grid-margin stretch-card" *ngIf="authService.hasAdminAccess()">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Add new Country</h4>
              <p class="card-description">Add new country to scan for external persons</p>
              <form class="form-sample">
                <div class="col-8">
                  <div class="form-group row">
                    <label class="col-6 col-form-label" for="countryCode">ISO Country Code (two-letter)</label>
                    <div class="col-3">
                      <input name="countryCode" class="form-control" id="countryCode" type="text" [(ngModel)]="countryCode"
                        [readOnly]="!authService.hasAdminAccess()" placeholder="" />
                    </div>
                  </div>
                </div>
                <div class="col-8">
                  <div class="form-group row">
                    <label class="col-6 col-form-label" for="name">Name</label>
                    <div class="col-6">
                      <input name="name" class="form-control" id="name" type="text" [(ngModel)]="name"
                        [readOnly]="!authService.hasAdminAccess()" placeholder="" />
                    </div>
                  </div>
                </div>
              </form>
              <button class="btn btn-outline-primary mb-2" (click)="addCountry()" type="submit"
                [disabled]="!authService.hasAdminAccess()" style="margin: 0px;">Add Country</button>
              <div class="form-group">
                <app-loader *ngIf="showLoader"></app-loader>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6 grid-margin stretch-card" *ngIf="authService.hasAdminAccess()">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title" (click)="refreshWikiDataSettings()">WikiData Scanner Settings</h4>
              <p class="card-description">Control scanning backend</p>
                <div class="col-8">
                  <div class="form-group row">
                    <label class="col-6 col-form-label" for="subject">Scanner status (scanning allowed)</label>
                    <div class="col-3">
                      <app-inline-loader *ngIf="wdscannerStatusLoader"></app-inline-loader>
                      <div class="input-group" *ngIf="!wdscannerStatusLoader">
                        <label class="switch">
                          <input type="checkbox" [(ngModel)]="wdscannerStatus" (change)="toggleWDScannerStatus()">
                          <span class="slider round"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group row">
                    <label class="col-4 col-form-label" for="subject">Parser status (processing of queue)</label>
                    <div class="col-2">
                      <app-inline-loader *ngIf="wdparserStatusLoader"></app-inline-loader>
                      <div class="input-group" *ngIf="!wdparserStatusLoader">
                        <label class="switch">
                          <input type="checkbox" [(ngModel)]="wdparserStatus" (change)="toggleWDParserStatus()">
                          <span class="slider round"></span>
                        </label>
                      </div>
                    </div>
                    <div class="col-6">
                      <label class="col-form-label">Current Parser Queue Depth: {{ wdparserQueueDepth }}</label>
                    </div>
                  </div>
                </div>
                <form class="form-sample">
                  <div class="col-8">
                    <div class="form-group row">
                      <label class="col-6 col-form-label" for="subject">Parser Concurrency</label>
                      <div class="col-3">
                        <app-inline-loader *ngIf="wdparserStatusLoader"></app-inline-loader>
                        <input name="subject" class="form-control" id="subject" type="text" [(ngModel)]="wdparserConcurrency" *ngIf="!wdparserStatusLoader"
                          [readOnly]="!authService.hasAdminAccess()" placeholder="" />
                      </div>
                    </div>
                  </div>
                  <div class="col-8">
                    <div class="form-group row">
                      <label class="col-6 col-form-label" for="subject">Scanner Offset</label>
                      <div class="col-3">
                        <app-inline-loader *ngIf="wdparserStatusLoader"></app-inline-loader>
                        <input name="wdScannerOffset" class="form-control" id="wdScannerOffset" type="text" [(ngModel)]="wdScannerOffset" *ngIf="!wdparserStatusLoader"
                          [readOnly]="!authService.hasAdminAccess()" placeholder="" />
                      </div>
                    </div>
                  </div>
                </form>
              <button class="btn btn-outline-primary mb-2" (click)="updateWikiDataConcurrency()" type="submit"
                [disabled]="!authService.hasAdminAccess()" style="margin: 0px;">Update WikiData Settings</button>
              <div class="form-group">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 grid-margin">
          <div class="card card-statistics">
            <div class="card-body p-0">
              <div class="row">
                <div class="col-md-12 col-lg-12">
                  <h1>List of all countries</h1>
                  <button (click)="refreshCountries()" class="btn">Refresh</button>
                </div>
                <div *ngIf="!countries" class="col-md-12 col-lg-12">
                  <app-loader></app-loader>
                </div>
              </div>
              <div class="row" *ngIf="countries">
                <div class="col-md-12 col-lg-12">
                  <table class="table">
                    <tr>
                      <th>ID</th>
                      <th>Name</th>
                      <th>Created</th>
                      <th>Last update</th>
                      <th>Last scan</th>
                      <th>Actions</th>
                    </tr>
                    <tr *ngFor="let c of countries">
                      <td>{{ c.id }}</td>
                      <td>{{ c.name }}</td>
                      <td>{{ c.created  | date:'yyyy-MMM-dd HH:mm' | uppercase  }}</td>
                      <td>{{ c.lastUpdate | date:'yyyy-MMM-dd HH:mm' | uppercase  }}</td>
                      <td>{{ c.lastScan | date:'yyyy-MMM-dd HH:mm' | uppercase  }}</td>
                      <td>
                        <button (click)="editCountry(c.id)" class="btn">Edit</button>
                        <button (click)="deleteCountry(c.id)" class="btn">Delete</button>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>