import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { BehaviorSubject, Observable, config } from 'rxjs';
import * as AWS from 'aws-sdk';
import * as AWSCognito from 'amazon-cognito-identity-js';
import { EnvironmentsService } from './environments.service';
import { User } from '../model/user';
import { Environment } from '../model/Environment';

@Injectable({
    providedIn: 'root'
})
export class CognitoService {

    private userPool: AWSCognito.CognitoUserPool;
    private selectedEnvironment: Environment;
    private runRefreshLoop = false;
    private loopRunning = false;

    poolData = {
        UserPoolId: undefined,
        ClientId: undefined,
        Paranoia: 7
    };


    constructor(
        private authentication: AuthenticationService,
        private environmentService: EnvironmentsService) {
        if (this.environmentService) {
            this.selectedEnvironment = this.environmentService.selectedEnv.value;
            this.environmentService.selectedEnv.subscribe((v) => {
                this.selectedEnvironment = v;

                if (v) {
                this.poolData = {
                    UserPoolId: this.selectedEnvironment.userPoolId,
                    ClientId: this.selectedEnvironment.clientId,
                    Paranoia: 7
                };
                this.userPool = new AWSCognito.CognitoUserPool(this.poolData);
                if (authentication.currentUser && authentication.currentUser.value && authentication.currentUser.value.username) {
                    const userData = {
                        Username: authentication.currentUser.value.username,
                        Pool: this.userPool
                    };
                    const cognitoUser = new AWSCognito.CognitoUser(userData);
                    authentication.currentUser.value.cognito = cognitoUser;
                }
                console.log('Updated login details to match environment details');
                }
            });
        }
    }


    changePassword(user: User, newPassword: string): Observable<User> {
        return Observable.create((observer) => {
            user.cognito.completeNewPasswordChallenge(newPassword, {
                name: user.fullname
            }, this.cognitoResponseHandler(user, this.userPool, this.authentication.currentUser,
                this.selectedEnvironment, observer, this.authentication.storeUser));
        });
    }

    confirmPassword(user: User, verificationCode: string, newPassword: string): Observable<User> {
        return Observable.create((observer) => {
            user.cognito.confirmPassword(verificationCode, newPassword, {
                onFailure(err) {
                    console.log('Error: ' + JSON.stringify(err, null, 4));
                    user.state = 'ERROR';
                    user.message = err.message;
                    observer.next(user);
                },
                onSuccess() {
                    console.log('Password confirmed');
                    user.state = 'PASS_CHANGE_OK';
                    observer.next(user);
                }
            });
        });
    }

    resendMFACode(user: User): Observable<User> {
        return Observable.create((observer) => {
            user.cognito.resendConfirmationCode((err, res) => {
                if (err) {
                    user.state = 'ERROR';
                    user.message = err.message;
                    observer.next(user);
                } else {
                    user.state = 'CHALLENGE_MFA';
                    observer.next(user);
                }
            });
        });
    }

    sendMFAResponse(user: User, mfa: string): Observable<User> {
        return Observable.create((observer) => {
            user.cognito.sendMFACode(mfa, this.cognitoResponseHandler(user, this.userPool, this.authentication.currentUser,
                this.selectedEnvironment, observer, this.authentication.storeUser));
        });
    }

    login(user: string, password: string, remember: boolean): Observable<User> {
        return Observable.create((observer) => {
            const authenticationData = {
                Username: user,
                Password: password,
            };
            const authenticationDetails = new AWSCognito.AuthenticationDetails(authenticationData);

            const userData = {
                Username: user,
                Pool: this.userPool
            };

            const cognitoUser = new AWSCognito.CognitoUser(userData);

            const u = new User();
            u.username = user;
            u.password = password;
            u.cognito = cognitoUser;
            u.rememberMe = remember;

            if (u.rememberMe) {
                this.startRefreshLoop();
            }

            cognitoUser.authenticateUser(authenticationDetails,
                this.cognitoResponseHandler(u, this.userPool, this.authentication.currentUser,
                    this.selectedEnvironment, observer, this.authentication.storeUser));
        });
    }

    resetPassword(user: string): Observable<User> {
        return Observable.create((observer) => {
            const userData = {
                Username: user,
                Pool: this.userPool
            };
            const cognitoUser = new AWSCognito.CognitoUser(userData);

            const u = new User();
            u.username = user;
            u.cognito = cognitoUser;
            u.rememberMe = false;

            cognitoUser.forgotPassword(this.cognitoResponseHandler(u, this.userPool,
                this.authentication.currentUser, this.selectedEnvironment, observer, this.authentication.storeUser));
        });
    }

    refreshTokens(user: User): Observable<User> {
        return Observable.create((observer) => {
            user.cognito.refreshSession(
                new AWSCognito.CognitoRefreshToken({ RefreshToken: user.refreshToken }), (err, session: AWSCognito.CognitoUserSession) => {
                    if (err) {
                        console.log('Error refreshing token: ' + JSON.stringify(err, null, 4));
                        user.state = 'ERROR';
                        user.message = 'Token refresh failed';
                        this.authentication.logout();
                    } else {
                        user.token = session.getIdToken().getJwtToken();
                        user.refreshToken = session.getRefreshToken().getToken();
                        console.log('User token was refreshed');
                        this.authentication.storeUser(user);
                        user.lastRefresh = Date.now();
                        observer.next(user);
                    }
                }
            );
        });
    }




    cognitoResponseHandler = (  u: User,
                                userPool: AWSCognito.CognitoUserPool,
                                currentUserSubject: BehaviorSubject<User>,
                                env: Environment,
                                observer: any,
                                storeUser: (User) => void,
                                forgotPaswordFlow?: boolean,
    ): AWSCognito.IAuthenticationCallback => {
        return {
            newPasswordRequired(result) {
                // console.log('newPasswordRequired: ' + JSON.stringify(result, null, 4));

                u.state = 'CHANGE_PASS';

                observer.next(u);
            },
            mfaSetup(result, more) {
                // console.log('mfaSetup: ' + JSON.stringify(result, null, 4));
            },
            mfaRequired(challengeName, challengeParameters) {
                // console.log('mfaRequired: ' + JSON.stringify(challengeParameters, null, 4));
                u.state = 'CHALLENGE_MFA';
                u.mfa = {
                    Medium: challengeParameters.CODE_DELIVERY_DELIVERY_MEDIUM,
                    Details: challengeParameters.CODE_DELIVERY_DESTINATION
                };
                observer.next(u);
            },
            customChallenge(result) {
                // console.log('customChallenge: ' + JSON.stringify(result, null, 4));
            },
            selectMFAType(result) {
                // console.log('selectMFAType: ' + JSON.stringify(result, null, 4));
            },
            totpRequired(result) {
                // console.log('totpRequired: ' + JSON.stringify(result, null, 4));
            },

            onSuccess(result) {
                // console.log('Callback: ' + JSON.stringify(result, null, 4));
                if (forgotPaswordFlow) {
                    u.state = 'CONFIRM_PASS';
                    currentUserSubject.next(u);
                    observer.next(u);
                    return;
                }
                const loggedInUser = userPool.getCurrentUser();
                u.cognito = loggedInUser;
                if (loggedInUser != null) {

                    loggedInUser.getSession((err, sessionResult) => {
                        // console.log('Session Callback: ' + JSON.stringify(sessionResult, null, 4));
                        if (sessionResult) {
                            console.log('Authenticated to Cognito User Pools!');

                            const loginKey = 'cognito-idp.' + env.region + '.amazonaws.com/' + env.userPoolId;
                            const options = {
                                IdentityPoolId: env.identityPoolId,
                                Logins: {}
                            };
                            options.Logins[loginKey] = result.getIdToken().getJwtToken();
                            AWS.config.credentials = new AWS.CognitoIdentityCredentials(options);
                            u.fullname = result.getIdToken().decodePayload().name;
                            u.email = result.getIdToken().decodePayload().email;
                            u.groups = sessionResult.getIdToken().payload['cognito:groups'];
                            const sts = new AWS.STS({
                                region: env.region
                            });
                            sts.getCallerIdentity({}, (error, data) => {
                                if (err) {
                                    this.onFailure(error);
                                } else {
                                    // console.log('AuthenticationService: Successfully set the AWS credentials - '
                                    // + JSON.stringify(data, null, 4));
                                    // u.awsAccount = data.Account;
                                    u.state = 'OK';

                                    u.token = result.getIdToken().getJwtToken();
                                    u.refreshToken = result.getRefreshToken().getToken();
                                    storeUser(u);
                                    currentUserSubject.next(u);
                                    observer.next(u);
                                }
                            });

                        }
                    });
                }
            },
            onFailure(err) {
                console.log('Error: ' + JSON.stringify(err, null, 4));
                u.state = 'ERROR';
                u.message = err.message;
                observer.next(u);
            }
        };
    }

    startRefreshLoop(): void {
        this.runRefreshLoop = true;

        if (!this.loopRunning) {
            console.log('Scheduling Refresh Loop Iteration');
            const me = this;
            this.loopRunning = true;
            setInterval(() => {
                console.log('Running background user refresh');
                const u = me.authentication.currentUserValue;
                if (u && u.rememberMe) {
                    me.refreshTokens(u).subscribe((us) => {
                        console.log('User refresh action done - trying do reschedule');
                        this.loopRunning = false;
                        me.startRefreshLoop();
                    });
                }
            }, 1000 * 60 * 10); // run every 10 minutes
        }
    }

    stopRefreshLoop(): void {
        this.runRefreshLoop = false;
    }
}
