import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { CaughtError } from '../model/CaughtError';
import { AuthenticationService } from './authentication.service';
import * as StackTraceParser from 'error-stack-parser';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { BehaviorSubject, Observable } from 'rxjs';
import { LogRecord } from '../model/log-record';

@Injectable({
  providedIn: 'root'
})
export class ErrorLogHandlerService {

  private allerrors: CaughtError[] = [];

  public errors: BehaviorSubject<CaughtError[]> = new BehaviorSubject(this.allerrors);


  constructor(
    private injector: Injector,
    private http: HttpClient,
    private auth: AuthenticationService
  ) { }


  public log(err: any): void {
    const t = new CaughtError();
    t.time = new Date();
    t.user = (this.auth.currentUserValue) ? this.auth.currentUserValue.fullname : 'no-user';
    t.name = err.name || null;
    t.message = err.message || err.toString();
    t.status = err.status || null;
    t.stack = err instanceof HttpErrorResponse ? null : JSON.stringify(StackTraceParser.parse(err));
    t.path = '';

    const location = this.injector.get(LocationStrategy);
    const url = location instanceof PathLocationStrategy ? location.path(true) : '';
    t.location = url;

    this.allerrors.push(t);
    this.errors.next(this.allerrors);

    console.error(new Date().toISOString() + ' <ERROR>: ', err);

    // this.http.put('v1/logs', t).subscribe(() => {
    //   console.log('Log event sent to server');
    // });
  }

  public getLogs(logStream: string, logGroup: string, query: string): Observable<LogRecord[]> {
     return this.http.post<LogRecord[]>('v1/logs/search', {
       logGroup: logGroup,
       logStream: logStream,
       message: query
     });
  }

}
