import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Country } from 'src/app/model/country';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CountriesService } from 'src/app/services/countries.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { TitleService } from 'src/app/services/title.service';
import { WikipediaScannerService } from 'src/app/services/wikipedia.service';

@Component({
  selector: 'app-edit-country',
  templateUrl: './edit-country.component.html',
  styleUrls: ['./edit-country.component.css']
})
export class EditCountryComponent implements OnInit {

  showLoader = true;
  countryCode: string;
  country: Country = {} as Country;

  wikisource: string;

  constructor(
    private route: ActivatedRoute,
    private titleService: TitleService,
    private countryService: CountriesService,
    private wikiScannerService: WikipediaScannerService,
    public authService: AuthenticationService,
    private notificationService: NotificationsService,
    private router: Router
  ) { }

  ngOnInit() {
    this.refresh();
  }

  refresh() {
    this.showLoader = true;
    this.countryCode = this.route.snapshot.paramMap.get('countryCode');
    this.countryService.getCountry(this.countryCode).subscribe(c => {
      this.country = c;
      if (!this.country.metadata) {
        this.country.metadata = {};
      }
      if (!this.country.metadata.wikipedia) {
        this.country.metadata.wikipedia = {};
        this.country.metadata.wikipedia.infoboxes = [];
      }
      this.titleService.setTitle('Edit: ' + c.name);
      this.showLoader = false;
    });
  }

  saveCountry() {
    this.showLoader = true;
    this.titleService.setTitle('Saving...');
    this.countryService.saveCountry(this.country).subscribe((s) => {
      this.notificationService.showInfo('Saved', s, 'info', 'Close', false);
      this.refresh();
    });
  }

  async editInfobox(inb: string) {
    const arr = this.country.metadata.wikipedia.infoboxes;

    const newValue = await this.notificationService.requestInput('Update value', 'Enter new value for ' + inb, 'info', 'Change', 'Cancel');

    if (!newValue) {
      return;
    }
    const index = arr.indexOf(inb, 0);
    if (index > -1) {
      arr[index] = newValue;
    }
  }

  async addInfobox() {
    const newValue = await this.notificationService.requestInput('Add value', 'Enter new value to search for', 'info', 'Add', 'Cancel');

    if (!newValue) {
      return;
    }
    this.country.metadata.wikipedia.infoboxes.push(newValue);
  }

  deleteInfobox(inb: string) {
    const arr = this.country.metadata.wikipedia.infoboxes;
    const index = arr.indexOf(inb, 0);
    if (index > -1) {
      arr.splice(index, 1);
    }
  }

  parseWikitext() {
    const startToken = '[[Template:'
    const endToken = ']]'

    let index = 0;
    let endIndex;

    const txt = this.wikisource;

    while (index > -1) {
      index = txt.indexOf(startToken, index);

      if (index > -1) {
        index += startToken.length;
      } else {
        break;
      }

      endIndex = txt.indexOf(endToken, index);

      const v = txt.substring(index, endIndex).split(' ').join('_');

      index = endIndex;
      this.country.metadata.wikipedia.infoboxes.push(v);
    }
  }

}
