import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guards';
import { LoginComponent } from './pages/login/login.component';
import { DatamodelComponent } from './pages/other/datamodel/datamodel.component';
import { ProfileSettingsComponent } from './pages/profile-settings/profile-settings.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ListUsersComponent } from './pages/other/list-users/list-users.component';
import { SearchLogsComponent } from './pages/other/search-logs/search-logs.component';
import { ManageComponent } from './pages/bugreports/manage/manage.component';
import { ListBatchJobsComponent } from './pages/other/batch/list-batch-jobs/list-batch-jobs.component';
import { CompareLogsComponent } from './pages/other/compare-logs/compare-logs.component';
import { ListCountriesComponent } from './pages/countries/list-countries/list-countries.component';
import { ClearExternalPersonsComponent } from './pages/other/settings/clear-external-persons/clear-external-persons.component';
import { EditCountryComponent } from './pages/countries/edit-country/edit-country.component';
import { EditCustomerComponent } from './pages/customers/edit-customer/edit-customer.component';
import { ListProvidersComponent } from './pages/providers/list-providers/list-providers.component';

export const routes: Routes = [
          { path: '', redirectTo: '/dashboard', pathMatch: 'full', canActivate: [AuthGuard] },
          { path: 'login', component: LoginComponent },
          { path: 'bugs', component: ManageComponent, data: { breadcrumb: 'Bugs' }, canActivate: [AuthGuard]},
          { path: 'bugs/:parentId', component: ManageComponent, data: { breadcrumb: 'Bugs' }, canActivate: [AuthGuard]},
          { path: 'dashboard', component: DashboardComponent, data: { breadcrumb: 'Dashboard' }, canActivate: [AuthGuard] },
          { path: 'providers', component: ListProvidersComponent, data: { breadcrumb: 'Route Providers' }, canActivate: [AuthGuard] },
          { path: 'customers/:customerId', component: EditCustomerComponent, data: { breadcrumb: 'Edit customer' }, canActivate: [AuthGuard] },
          { path: 'countries', component: ListCountriesComponent, data: { breadcrumb: 'Countries' }, canActivate: [AuthGuard] },
          { path: 'countries/:countryCode', component: EditCountryComponent, data: { breadcrumb: 'Countries' }, canActivate: [AuthGuard] },
           { path: 'profile', component: ProfileSettingsComponent, data: { breadcrumb: 'Settings'}, canActivate: [AuthGuard] },
           { path: 'settings/external-persons/clear', component: ClearExternalPersonsComponent, data: { breadcrumb: 'Settings'}, canActivate: [AuthGuard] },
           { path: 'datamodel', component: DatamodelComponent, data: { breadcrumb: 'DataModel'}, canActivate: [AuthGuard] },
           { path: 'logs/compare', component: CompareLogsComponent, data: { breadcrumb: 'Logs'}, canActivate: [AuthGuard]  },
           { path: 'logs/:logGroup/:logStream/:query', component: SearchLogsComponent, data: { breadcrumb: 'Logs'}, canActivate: [AuthGuard]  },
           { path: 'users', component: ListUsersComponent, data: { breadcrumb: 'Users'}, canActivate: [AuthGuard]  },
           { path: 'settings/batch', component: ListBatchJobsComponent, data: { breadcrumb: 'Settings / Batch'}, canActivate: [AuthGuard] },
  // { path: 'error', component: ErrorComponent, data: { breadcrumb: 'Error' } },
  // { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
