import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MetataggerRouteProvider } from '../model/metatagger-route-provider';

@Injectable({
  providedIn: 'root'
})
export class ProvidersService {

  public url = 'v1';

  constructor(public http: HttpClient) { }

  listProviders(): Observable<MetataggerRouteProvider[]> {
      return this.http.get<MetataggerRouteProvider[]>(this.url + '/route-providers?v=' + new Date().getTime());
  }

  createRouteProvider(route: MetataggerRouteProvider): Observable<string> {
      return this.http.put<string>(this.url + '/route-providers', route);
  }

  updateRouteProvider(route: MetataggerRouteProvider): Observable<string> {
      return this.http.post<string>(this.url + '/route-providers/' + route.id, route);
  }

  deleteRouteProvider(id: string): Observable<string> {
      return this.http.delete<string>(this.url + '/route-providers/' + id, {});
  }

  getRouteProvider(id: string): Observable<MetataggerRouteProvider> {
      return this.http.get<MetataggerRouteProvider>(this.url + '/route-providers/' + id + '?v=' + Date.now(), {});
  }

}
