import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class WikiDataScannerService {

  public url = 'v1';

  constructor(public http: HttpClient,
              private authenticationService: AuthenticationService) { }

  getScannerStatus(): Observable<{ status: boolean, offset: number}> {
      return this.http.get<{ status: boolean, offset: number}>(this.url + '/wikidata/scanner-status?v=' + new Date().getTime());
  }

  getParserStatus(): Observable<{ status: string, concurrent: number, parserQueueDepth: number}> {
      return this.http.get<{ status: string, concurrent: number, parserQueueDepth: number}>(this.url + '/wikidata/parser-status?v=' + new Date().getTime());
  }

  updateScannerStatus(v: boolean): Observable<void> {
    return this.http.post<void>(this.url + '/wikidata/scanner-status', {enabled: v});
  }

  updateParserStatus(v: boolean): Observable<void> {
    return this.http.post<void>(this.url + '/wikidata/parser-status', {enabled: v});
  }

  updateParserConcurrency(v: number): Observable<string> {
    return this.http.post<string>(this.url + '/wikidata/parser-concurrency', {newValue: v});
  }

  updateScannerOffset(v: number): Observable<string> {
    return this.http.post<string>(this.url + '/wikidata/scanner-offset', {newValue: v});
  }

}
