

export class MetataggerRouteProvider {

    public id: string;
    public name: string;
    public description: string;
    public kinds: string[] = [];
    public enabled: boolean;
    public cost: number;
    public costUnit: string;
    public customers?: string[];
    public context: string;
    public website: string;
    public created: number;
    public lastUpdate: number;

}
