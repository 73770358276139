import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MetataggerCustomer } from 'src/app/model/metatagger-customer';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CustomersService } from 'src/app/services/customers.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { TitleService } from 'src/app/services/title.service';

@Component({
  selector: 'app-edit-customer',
  templateUrl: './edit-customer.component.html',
  styleUrls: ['./edit-customer.component.css']
})
export class EditCustomerComponent implements OnInit {

  showLoader = true;
  id: string;
  customer: MetataggerCustomer = {} as MetataggerCustomer;

  wikisource: string;

  constructor(
    private route: ActivatedRoute,
    private titleService: TitleService,
    private customerService: CustomersService,
    public authService: AuthenticationService,
    private notificationService: NotificationsService,
    private router: Router
  ) { }

  ngOnInit() {
    this.refresh();
  }

  refresh() {
    this.showLoader = true;
    this.id = this.route.snapshot.paramMap.get('customerId');
    this.customerService.fetchCustomer(this.id).subscribe(c => {
      this.customer = c;
      this.titleService.setTitle('Edit: ' + c.id);
      this.showLoader = false;
    });
  }

  saveCustomer() {
    this.showLoader = true;
    this.titleService.setTitle('Saving...');
    this.customerService.saveCustomer(this.customer).subscribe((s) => {
      this.notificationService.showInfo('Saved', s, 'info', 'Close', false);
      this.refresh();
    });
  }

}
