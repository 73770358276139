import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { User } from 'src/app/model/user';
import { CognitoService } from 'src/app/services/cognito.service';
import { TitleService } from 'src/app/services/title.service';

@Component({
  selector: 'app-profile-settings',
  templateUrl: './profile-settings.component.html',
  styleUrls: ['./profile-settings.component.css']
})
export class ProfileSettingsComponent implements OnInit {


  showLoader = false;
  tokenExpiry: Date;
  refreshTokenExpiry: Date;
  status: string;
  currentUser: User;

  constructor(
    private authService: AuthenticationService,
    private cognitoService: CognitoService,
    private titleService: TitleService
  ) { }

  ngOnInit() {
    this.titleService.setTitle('Profile');
    this.refreshView();
  }

  refreshView() {
    const u = this.authService.currentUser;

    if (u && u.value && u.value.token) {
      const p = u.value.token.split('.')[1];
      const payload = JSON.parse(atob(p));
      this.tokenExpiry = new Date(payload.exp * 1000);
      this.currentUser = u.value;
    }
    this.showLoader = false;
  }

  refreshToken() {
    this.showLoader = true;
    const u = this.authService.currentUser;

    this.cognitoService.refreshTokens(u.value).subscribe((user) => {
      const p = user.token.split('.')[1];
      const payload = JSON.parse(atob(p));
      this.tokenExpiry = new Date(payload.exp * 1000);
      this.showLoader = false;
    }, (err) => {
      this.status = err;
      this.showLoader = false;
    });
  }

  updateRememberMe() {
    if (this.currentUser.rememberMe) {
      this.cognitoService.startRefreshLoop();
    }
  }

}
