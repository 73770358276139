import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { EnvironmentsService } from 'src/app/services/environments.service';
import { User } from 'src/app/model/user';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CognitoService } from 'src/app/services/cognito.service';
import { Environment } from 'src/app/model/Environment';
import { TitleService } from 'src/app/services/title.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  returnUrl: string;
  year = new Date().getFullYear();
  // states used to control UI
  public firstFactor = true;
  public loginInProgress = false;
  public changePassword = false;
  public confirmPassword = false;
  public askForMFA = false;
  public buttonEnabled = false;

  // texts
  public title = 'AI Content Assister - Please login';
  public buttonText = 'Login';
  public mfaDetails = '';

  public username = '';
  public fullname = '';
  public password = '';
  public mfaCode = '';
  public verificationCode = '';
  public rememberMe = false;

  public verifyPassword = '';

  // password validation state
  public hasLowerCase = false;
  public hasUpperCase = false;
  public hasNumber = false;

  public message: string;
  private user: User;
  public environment: Environment;

  constructor(
    private authService: CognitoService,
    private userService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
    private envService: EnvironmentsService,
    private titleService: TitleService
  ) { }

  ngOnInit() {
    this.titleService.setTitle('Metatagger Login');
    if (this.userService.currentUserValue) {
      this.router.navigate(['/profile']);
    }
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
    this.title = 'Fetching server settings...';
    this.envService.selectedEnv.subscribe((e) => {
      this.environment = e;
      if (e) {
        this.title = 'Login to ' + e.name;
        this.buttonEnabled = true;
      } else {
      //  console.log('Environment was null');
      }
    });
  }

  doLogin() {
    this.message = '';
    if (!this.changePassword && this.firstFactor && this.username.length > 1 && this.password.length > 3) {
      console.log('Login: ' + this.username + ' [' + this.rememberMe + ']');
      this.loginInProgress = true;
      this.authService.login(this.username, this.password, this.rememberMe).pipe(first()).subscribe((u) => {
        this.loginInProgress = false;
        this.user = u;

        if (u.state === 'OK') {
          this.router.navigate([this.returnUrl]);
        } else if (u.state === 'CHANGE_PASS') {
          this.changePassword = true;
          this.password = '';
          this.title = 'Password needs to be changed';
          this.buttonEnabled = false;
          this.buttonText = 'Change Password';
        } else if (u.state === 'CHALLENGE_MFA') {
          this.firstFactor = false;
          this.askForMFA = true;
          this.changePassword = false;
          this.title = 'Please provide the code from ' + u.mfa.Medium;
          this.mfaDetails = 'sent to: ' + u.mfa.Details;
          this.buttonText = 'Submit Code';
        } else if (u.state === 'ERROR') {
          this.message = u.message;
        }
      });
    } else if (this.changePassword && this.buttonEnabled) {
      console.log('Changing password for user ' + this.fullname);
      this.user.fullname = this.fullname;
      this.loginInProgress = true;
      this.authService.changePassword(this.user, this.password).pipe(first()).subscribe((u) => {
        this.loginInProgress = false;
        this.user = u;
        if (u.state === 'PASS_CHANGE_OK') {
          this.changePassword = false;
          this.password = '';
          this.title = 'Password was changed - please login with new password';
        } else if (u.state === 'CHALLENGE_MFA') {
          this.firstFactor = false;
          this.askForMFA = true;
          this.changePassword = false;
          this.title = 'Please provide the code from ' + u.mfa.Medium;
          this.mfaDetails = 'sent to: ' + u.mfa.Details;
          this.buttonText = 'Submit Code';
        } else  if (u.state === 'OK') {
          this.changePassword = false;
          this.router.navigate([this.returnUrl]);
        } else if (u.state === 'ERROR') {
          this.message = u.message;
        }
      });
    } else if (this.confirmPassword && this.buttonEnabled) {
      console.log('Confirming password for user ' + this.username);
      this.loginInProgress = true;
      this.authService.confirmPassword(this.user, this.verificationCode, this.password).pipe(first()).subscribe((u) => {
        this.loginInProgress = false;
        this.user = u;
        if (u.state === 'PASS_CHANGE_OK') {
          this.changePassword = false;
          this.password = '';
          this.firstFactor = true;
          this.title = 'Password was changed - please login with new password';
        } else if (u.state === 'CHALLENGE_MFA') {
          this.firstFactor = false;
          this.askForMFA = true;
          this.changePassword = false;
          this.title = 'Please provide the code from ' + u.mfa.Medium;
          this.mfaDetails = 'sent to: ' + u.mfa.Details;
          this.buttonText = 'Submit Code';
        } else if (u.state === 'ERROR') {
          this.message = u.message;
        }
      });
    } else if (this.askForMFA) {
      console.log('Sending MFA response for user ' + this.fullname);
      this.loginInProgress = true;
      this.user.rememberMe = this.rememberMe;
      this.authService.sendMFAResponse(this.user, this.mfaCode).pipe(first()).subscribe((u) => {
        this.loginInProgress = false;
        this.user = u;
        if (u.state === 'OK') {
          this.askForMFA = false;
          this.firstFactor = true;
          console.log('Redirecting user to: ' + this.returnUrl);
          this.router.navigate([this.returnUrl]);
        } else if (u.state === 'ERROR') {
          this.message = u.message;
        }
      });
    }
  }

  handleLoginResponse(u: User) {
    console.log('Recevied Response');
    this.loginInProgress = false;
    this.user = u;

    if (u.state === 'OK') {
      this.router.navigate([this.returnUrl]);
    } else if (u.state === 'CHANGE_PASS') {
      this.changePassword = true;
      this.password = '';
      this.title = 'Password needs to be changed';
    } else if (u.state === 'PASS_CHANGE_OK') {
      this.changePassword = false;
      this.password = '';
      this.title = 'Password was changed - please login with new password';
    } else if (u.state === 'ERROR') {
      this.message = u.message;
    }
  }

  validatePassword(event: any) {
    if (event && event.key === 'Enter') {
      this.doLogin();
      return;
    }
    if (this.changePassword || this.confirmPassword) {
      this.hasLowerCase = false;
      this.hasUpperCase = false;
      this.hasNumber = false;
      for (let i = 0; i < this.password.length; i++) {
        const c = this.password.charAt(i);
        if (isNaN(Number(c)) && !this.hasLowerCase && c === c.toLowerCase()) {
          this.hasLowerCase = true;
        } else if (!this.hasNumber && !isNaN(Number(c))) {
          this.hasNumber = true;
        } else if (isNaN(Number(c)) && !this.hasUpperCase && c === c.toUpperCase()) {
          this.hasUpperCase = true;
        }
      }
      this.buttonEnabled = this.hasLowerCase && this.hasUpperCase && this.hasNumber && this.password === this.verifyPassword;
      if (this.confirmPassword) {
        this.buttonEnabled = this.buttonEnabled && this.verificationCode.length === 6;
      }
    } else {
      this.buttonEnabled = this.password.length > 3;
    }
  }

  forgotPassword() {
    this.authService.resetPassword(this.username).subscribe((u) => {
      if (u.state === 'CONFIRM_PASS') {
        this.firstFactor = false;
        this.confirmPassword = true;
        this.title = 'Enter verification code and new password';
        this.buttonText = 'Change password';
      } else if (u.state === 'ERROR') {
        this.message = u.message;
      } else {
        console.log('Unexpected state :' + u.state);
      }
    });
  }

  resendMfa() {
    this.firstFactor = true;
    this.doLogin();
  }

}
