<div class="container-fluid page-body-wrapper">
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="row">
        <div class="col-12 grid-margin stretch-card" *ngIf="authService.hasAdminAccess()">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Define Route Provider</h4>
              <p class="card-description">Define or edit a Route Provider</p>
              <div class="row">
                <div class="col-6">
                  <form class="form-sample">
                    <div class="col-8">
                      <div class="form-group row">
                        <label class="col-6 col-form-label" for="providerId">Provider ID</label>
                        <div class="col-6">
                          <input name="providerId" class="form-control" id="providerId" type="text"
                            [(ngModel)]="temp.id" [readOnly]="!authService.hasAdminAccess()" placeholder="" />
                        </div>
                      </div>
                    </div>
                    <div class="col-8">
                      <div class="form-group row">
                        <label class="col-6 col-form-label" for="name">Name</label>
                        <div class="col-6">
                          <input name="name" class="form-control" id="name" type="text" [(ngModel)]="temp.name"
                            [readOnly]="!authService.hasAdminAccess()" placeholder="" />
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group row">
                        <label class="col-4 col-form-label" for="desc">Description</label>
                        <div class="col-6">
                          <textarea name="desc" class="form-control" id="desc" rows="3" [(ngModel)]="temp.description"
                            [readOnly]="!authService.hasAdminAccess()" placeholder=""></textarea>
                        </div>
                      </div>
                    </div>
                    <div class="col-8">
                      <div class="form-group row">
                        <label class="col-6 col-form-label" for="Website">Website</label>
                        <div class="col-6">
                          <input name="Website" class="form-control" id="Website" type="text" [(ngModel)]="temp.website"
                            [readOnly]="!authService.hasAdminAccess()" placeholder="" />
                        </div>
                      </div>
                    </div>
                  </form>
                  <button class="btn btn-outline-primary mb-2" (click)="addRouteProvider()" type="submit"
                    [disabled]="!authService.hasAdminAccess()" style="margin: 0px;">Save Route Provider</button>
                  <button class="btn btn-outline-warning mb-2" *ngIf="updateMode" (click)="cancelEdit()" type="button"
                    [disabled]="!authService.hasAdminAccess()" style="margin: 0px;">Cancel Edit</button>
                  <div class="form-group">
                    <app-loader *ngIf="showLoader"></app-loader>
                  </div>
                </div>
                <div class="col-6">
                  <form class="form-sample">
                    <div class="col-8">
                      <div class="form-group row">
                        <label class="col-6 col-form-label" for="Cost">Cost</label>
                        <div class="col-6">
                          <input name="Cost" class="form-control" id="Cost" type="text"
                            [(ngModel)]="temp.cost" [readOnly]="!authService.hasAdminAccess()" placeholder="" />
                        </div>
                      </div>
                    </div>
                    <div class="col-8">
                      <div class="form-group row">
                        <label class="col-6 col-form-label" for="Costunit">Cost unit</label>
                        <div class="col-6">
                          <input name="Costunit" class="form-control" id="Costunit" type="text"
                            [(ngModel)]="temp.costUnit" [readOnly]="!authService.hasAdminAccess()" placeholder="" />
                        </div>
                      </div>
                    </div>
                    <div class="col-8">
                      <div class="form-group row">
                        <label class="col-6 col-form-label" for="providerId">Enabled</label>
                        <div class="col-6">
                          <div class="input-group">
                            <label class="switch">
                              <input type="checkbox" [(ngModel)]="temp.enabled" name="enabled">
                              <span class="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-8">
                      <div class="form-group row">
                        <label class="col-6 col-form-label" for="tempCustomer">Customers</label>
                        <div class="col-4">
                          <select name="customer" [(ngModel)]="tempCustomer" class="form-control" >
                            <option *ngFor="let c of customers">{{c.id}}</option>
                          </select>
                        </div>
                        <div class="col-2">
                          <button (click)="addCustomer()" class="btn btn-outline-primary btn-sm" style="margin: 0px;"><i
                              class="icon-plus"></i></button>
                        </div>
                      </div>
                    </div>
                    <div class="col-8">
                      <div class="form-group row">
                        <label class="col-6 col-form-label" for="nobody"></label>
                        <div class="col-6">
                          <ul *ngIf="temp.customers">
                            <li *ngFor="let k of temp.customers">{{ k }} <a (click)="removeCustomer(k)"
                                style="cursor: pointer;">(remove)</a></li>
                          </ul>
                          <p *ngIf="!temp.customers">Available to all customers</p>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 grid-margin">
          <div class="card card-statistics">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12 col-lg-12">
                  <h4  class="card-title">List of all Route Providers</h4>
                  <button (click)="refreshRouteProviders()" class="btn">Refresh</button>
                </div>
                <div *ngIf="!providers" class="col-md-12 col-lg-12">
                  <app-loader></app-loader>
                </div>
              </div>
              <div class="row" *ngIf="providers">
                <div class="col-md-12 col-lg-12">
                  <table class="table">
                    <tr>
                      <th>#</th>
                      <th>ID</th>
                      <th>Name</th>
                      <th>Enabled</th>
                      <th>Cost</th>
                      <th>Cost unit</th>
                      <th>Customers</th>
                      <th>Created</th>
                      <th>Last update</th>
                      <th>Actions</th>
                    </tr>
                    <tr *ngFor="let c of providers; index as i" [style]="(!c.enabled) ? 'background-color: lightgrey;' : ''" >
                      <td>{{ (i + 1) }}</td>
                      <td>{{ c.id }}</td>
                      <td>{{ c.name }}</td>
                      <td>{{ c.enabled }}</td>
                      <td>{{ c.cost }}</td>
                      <td>{{ c.costUnit }}</td>
                      <td *ngIf="!c.customers">Available to all</td>
                      <td *ngIf="c.customers">
                        <ul>
                          <li *ngFor="let cu of c.customers">{{ cu }}</li>
                        </ul>
                      </td>
                      <td>{{ c.created | date:'yyyy-MMM-dd HH:mm' | uppercase  }}</td>
                      <td>{{ c.lastUpdate | date:'yyyy-MMM-dd HH:mm' | uppercase  }}</td>
                      <td>
                        <button (click)="editRouteProvider(c)" class="btn">Edit</button>
                        <button (click)="deleteRouteProvider(c.id)" class="btn">Delete</button>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>