<div class="container-fluid page-body-wrapper">
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="row">
        <div class="col-12 grid-margin stretch-card" *ngIf="authService.hasAdminAccess()">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Add new Customer</h4>
              <p class="card-description">Create new customer in this environment</p>
              <form class="form-sample">
                <div class="col-8">
                  <div class="form-group row">
                    <label class="col-3 col-form-label" for="subject">Unique ID</label>
                    <div class="col-3">
                      <input name="subject" class="form-control" id="subject" type="text" [(ngModel)]="id"
                        [readOnly]="!authService.hasAdminAccess()" placeholder="" />
                    </div>
                  </div>
                </div>
                <div class="col-8">
                  <div class="form-group row">
                    <label class="col-3 col-form-label" for="subject">Name</label>
                    <div class="col-3">
                      <input name="subject" class="form-control" id="subject" type="text" [(ngModel)]="name"
                        [readOnly]="!authService.hasAdminAccess()" placeholder="" />
                    </div>
                  </div>
                </div>
              </form>
              <button class="btn btn-outline-primary mb-2" (click)="createCustomer()" type="submit"
                [disabled]="!authService.hasAdminAccess()" style="margin: 0px;">Create Customer</button>
              <div class="form-group">
                <app-loader *ngIf="showLoader"></app-loader>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 grid-margin">
          <div class="card card-statistics">
            <div class="card-body p-0">
              <div class="row">
                <div class="col-md-12 col-lg-12">
                  <h1>List of all customers</h1>
                  <button (click)="refreshCustomers()" class="btn">Refresh</button>
                </div>
                <div *ngIf="!customers" class="col-md-12 col-lg-12">
                  <app-loader></app-loader>
                </div>
              </div>
              <div class="row" *ngIf="customers">
                <div class="col-md-12 col-lg-12">
                  <table class="table">
                    <tr>
                      <th>ID</th>
                      <th>Name</th>
                      <th>Created</th>
                      <th>Last update</th>
                      <th>Status</th>
                      <th>Subscription Level</th>
                      <th>Active</th>
                      <th>Actions</th>
                    </tr>
                    <tr *ngFor="let c of customers">
                      <td>{{ c.id }}</td>
                      <td>{{ c.name }}</td>
                      <td>{{ c.created  | date:'yyyy-MMM-dd HH:mm' | uppercase  }}</td>
                      <td>{{ c.lastUpdate | date:'yyyy-MMM-dd HH:mm' | uppercase  }}</td>
                      <td>{{ c.status }}</td>
                      <td>{{ levels[c.subscriptionLevel] }}</td>
                      <td>{{ c.active }}</td>
                      <td>
                        <button (click)="editCustomer(c.id)" class="btn">Edit</button>
                        <button (click)="redeployCustomer(c.id)" class="btn">Redeploy</button>
                        <button (click)="deleteCustomer(c.id)" class="btn">Delete</button>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>