import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProvidersService } from 'src/app/services/providers.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { TitleService } from 'src/app/services/title.service';
import { MetataggerRouteProvider } from 'src/app/model/metatagger-route-provider';
import { CustomersService } from 'src/app/services/customers.service';
import { MetataggerCustomer } from 'src/app/model/metatagger-customer';

@Component({
  selector: 'app-list-providers',
  templateUrl: './list-providers.component.html',
  styleUrls: ['./list-providers.component.css']
})
export class ListProvidersComponent implements OnInit {

  showLoader = true;
  updateMode = false;

  tempKind: string;
  tempCustomer: string;
  providerId: string;
  providers: MetataggerRouteProvider[];
  customers: MetataggerCustomer[];

  temp = new MetataggerRouteProvider();

  constructor(
    private titleService: TitleService,
    private providersService: ProvidersService,
    private customersService: CustomersService,
    public authService: AuthenticationService,
    private notificationService: NotificationsService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.refreshRouteProviders();
    this.refreshCustomers();
    this.titleService.setTitle('Route Providers');
  }

  refreshRouteProviders() {
    this.providers = undefined;
    this.providersService.listProviders().subscribe(p => {
      p.sort((a, b) => a.name.localeCompare(b.name));
      this.providers = p;
      this.cancelEdit();
      this.showLoader = false;
    });
  }

  refreshCustomers() {
    this.customersService.listCustomers().subscribe(c => {
      this.customers = c;
      this.tempCustomer = c[0].id;
    });
  }

  addRouteProvider() {
    this.showLoader = true;

    if (this.updateMode) {
      this.providersService.updateRouteProvider(this.temp).subscribe((s) => {
        this.notificationService.showInfo('Server said', s, 'info', 'Close', false);
        this.refreshRouteProviders();
      })

    } else {
      this.providersService.createRouteProvider(this.temp).subscribe((s) => {
        this.notificationService.showInfo('Server said', s, 'info', 'Close', false);
        this.refreshRouteProviders();
      })

    }
  }

  cancelEdit() {
    this.updateMode = false;
    this.temp = new MetataggerRouteProvider();
  }

  editRouteProvider(p: MetataggerRouteProvider) {
    this.temp = JSON.parse(JSON.stringify(p));
    this.updateMode = true;
  }

  addKind() {
    this.temp.kinds.push(this.tempKind);
  }

  removeKind(v: string) {
    this.temp.kinds.splice(this.temp.kinds.indexOf(v));
  }

  addCustomer() {
    if (!this.temp.customers) {
      this.temp.customers = [];
    }
    this.temp.customers.push(this.tempCustomer);
  }

  removeCustomer(v: string) {
    this.temp.customers.splice(this.temp.customers.indexOf(v));
    if (this.temp.customers.length == 0) {
      this.temp.customers = undefined;
    }
  }

  async deleteRouteProvider(id: string) {
    const resp = await this.notificationService.showQuestion('Delete Route Provider?',
    'Route Provider with id ' + id + ' will be deleted. Any customer using this provider will no long be able to do so.', 'warning', 'Delete', 'Cancel');

    if (resp) {
      this.showLoader = true;
      this.providersService.deleteRouteProvider(id).subscribe((c) => {
      this.refreshRouteProviders();
      });
    }
  }

}
