import * as moment from 'moment-timezone';

export class CaughtError {

    time: moment.Moment;
    user: string;
    name: string;

    location: string;
    path: string;
    status: string;
    message: string;
    stack: string;

}