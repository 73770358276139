import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MetataggerCustomer } from 'src/app/model/metatagger-customer';
import { Nationality } from 'src/app/model/Nationality';
import { SubscriptionLevel } from 'src/app/model/subscription-level';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CustomersService } from 'src/app/services/customers.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { TitleService } from 'src/app/services/title.service';


declare function Chart(canvas: any, settings: any): void;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  regions: Nationality[];
  customers: MetataggerCustomer[];
  levels = SubscriptionLevel;

  id: string;
  name: string;
  showLoader = false;

  constructor(
    private titleService: TitleService,
    private customerService: CustomersService,
    public authService: AuthenticationService,
    private notificationService: NotificationsService,
    private router: Router
  ) { }

  ngOnInit() {
    this.titleService.setTitle('Administration');
    this.refreshCustomers();
  }

  refreshCustomers() {
    this.customers = undefined;
    this.customerService.listCustomers().subscribe((l) => this.customers = l);
  }

  editCustomer(id: string) {
    this.router.navigate(['/customers/' + id])
  }

  redeployCustomer(id: string) {
    this.customers = undefined;
    this.customerService.redeployCustomer(id).subscribe((s) => {
      this.refreshCustomers();
      this.notificationService.showInfo('Redeploy Requested', s, 'info', 'Ok', false);
      this.showLoader = false;
    }, (err) => {
      this.refreshCustomers();
      console.log(JSON.stringify(err));
      this.notificationService.showInfo('Error starting redeploy', err.error.Error.message, 'warning', 'Close', false);
      this.showLoader = false;
    });

  }

  async deleteCustomer(id: string) {
    const confirmed = await this.notificationService.showQuestion('Delete Customer "' + id + '"',
              'Are you sure? Customer and all data will be deleted. This cannot be undone!', 'warning', 'Yes - Delete', 'Cancel');

    if (confirmed) {

      this.customers = undefined;
      this.customerService.deleteCustomer(id).subscribe((s) => {
        this.refreshCustomers();
        this.notificationService.showInfo('Delete started', s, 'info', 'Ok', false);
        this.showLoader = false;
      }, (err) => {
        this.refreshCustomers();
        console.log(JSON.stringify(err));
        this.notificationService.showInfo('Error deleting customer', err.error.Error.message, 'warning', 'Close', false);
        this.showLoader = false;
      });
    }
  }

  createCustomer() {
    this.showLoader = true;
    this.customerService.createCustomer(this.id, this.name).subscribe((s) => {
      this.refreshCustomers();
      this.notificationService.showInfo('New Customer Created', s, 'info', 'Ok', false);
      this.showLoader = false;
    }, (err) => {
      this.refreshCustomers();
      console.log(JSON.stringify(err));
      this.notificationService.showInfo('Error Creating new Customer', err.error.Error.message, 'warning', 'Close', false);
      this.showLoader = false;
    });
  }


}
